import { Link } from "gatsby"
import React from "react"
import { AdminLogout, isAdminLoggedIn } from "../../services/adminAuth"

const AdminNavbar = () => {
  return (
    <nav className="navbar navbar-dark navbar-expand-lg bg-dark">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/superAdmin0rama">
          <img
            src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/swiftcloud_footer_logo_punched.png`}
            alt="SwiftCloud"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="/superAdmin0rama"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                WEAUs
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <Link to={"/superAdmin0rama"} className="dropdown-item">
                    <i className="bi bi-pencil-square"></i> eSign
                  </Link>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    <i className="bi bi-envelope-open-fill"></i>
                    Emails Sent
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    <i className="bi bi-currency-dollar"></i> Payments
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    <i className="bi bi-telephone-fill"></i> Active Phone #s
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    <i className="bi bi-box-arrow-in-right"></i> Logins
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/superAdmin0rama/users">
                <i className="bi bi-people-fill"></i> All Users
              </Link>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Operations
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="#">
                    <i className="bi bi-database-fill"></i> Docs Queue
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    <i className="bi bi-building-fill"></i> Industry Kit
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="Updater_swiftlocal.html"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                SwiftLocal
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link to={"/superAdmin0rama/swiftLocal/updatePages"} className="dropdown-item">
                    <i className="bi bi-files"></i> Update Pages
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#"
                onClick={() => {
                  if (isAdminLoggedIn()) {
                    AdminLogout()
                  }
                }}
              >
                <i className="bi bi-power"></i> Log Out
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default AdminNavbar
